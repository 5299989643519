<template>
  <div id="member-activity-edit">
    <v-overlay :value="OverlayLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card elevation="1" color="#F5F5F5">
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
        <v-spacer></v-spacer>
        <v-row wrap>
          <v-col cols="12" md="12" lg="12" class="text-right">
            <v-btn
                @click.prevent="redirectPreviousPage"
                color="#EDBE38"
                elevation="30"
                rounded
                medium
                class="font-size-h6 mr-3 px-3 py-3 white--text"
            >
                <!-- <v-icon dark> mdi-arrow-left-circle </v-icon> &nbsp; -->Back
            </v-btn>
            <!-- <router-link to="/my-activity/list">
              <v-btn
                color="#8950FC"
                elevation="30"
                rounded
                medium
                class="font-size-h6 mr-3 px-3 py-3 white--text"
              >
                My Activity List
              </v-btn> -->
            </router-link>
            <v-btn
              medium
              @click.prevent="refreshPageData"
              color="#a4c639"
              elevation="30"
              rounded
              class="font-size-h6 mr-3 px-3 py-3 white--text"
            >
              Refresh
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
            <v-card elevation="3" color="#3a67b1" v-if="MemeberFullname != ''">
                <v-card-text>
                  <v-row wrap>
                    <v-col align="center" cols="12" md="12" class="mycard-title-2 fsize-5 fcolor-1">
                        <p> Welcome {{ MemeberFullname }}, </p>
                    </v-col>
                  </v-row>
                </v-card-text>
            </v-card>
            <br /><br />

            <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
                variant="primary"
                type="grow"
                label="Loading..."
            ></b-spinner>
            </div>

            <v-form ref="form1" v-model="valid1" lazy-validation>
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                About the event &nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="#F19A3E"> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span class="mycard-text2">
                    Enter the event information
                  </span>
                </v-tooltip>
              </v-card-title>
              <v-container>
                <v-row wrap class="mt-4">
                  <v-col cols="12" sm="12" md="3" lg="3">
                    <label>
                      <h6><span class="text-danger">* </span>Event Date</h6>
                    </label>
                    <v-dialog ref="dialog" v-model="DatePicker" width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="ActivityDate"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="ActivityDate"
                        @input="DatePicker = false"
                      ></v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" lg="3">
                    <label>
                      <h6><span class="text-danger">* </span>Event Level</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="OrganizationTypeOptionsLoading"
                      :items="OrganizationTypeOptions"
                      :rules="OrganizationTypeRules"
                      v-model="OrganizationType"
                      required
                      outlined
                      dense
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" lg="3">
                    <label>
                      <h6><span class="text-danger">* </span> Event type</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      v-model="EventType"
                      :rules="EventTypeRules"
                      :items="EventTypeOptions"
                      :loading="EventTypeOptionsLoading"
                      required
                      outlined
                      dense
                      clearable
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" lg="3">
                    <h6><span class="text-danger">*</span> Event Name</h6>
                    <v-text-field
                      v-model="EventName"
                      :rules="EventNameRules"
                      placeholder="Event Name"
                      hint="Enter event name"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                Host Information &nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="#F19A3E"> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span class="mycard-text2">
                    Enter the host information
                  </span>
                </v-tooltip>
              </v-card-title>
              <v-container>
                <v-row wrap class="mt-4">
                  <v-col cols="12" sm="12" md="3" lg="3">
                    <label>
                      <h6><span class="text-danger">* </span>Host Zone</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="ZoneCodeOptionsLoading"
                      :items="ZoneCodeOptions"
                      :rules="ZoneCodeRules"
                      v-model="ZoneCode"
                      required
                      outlined
                      dense
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" lg="3">
                    <label>
                      <h6><span class="text-danger">* </span>Host LO</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="LomCodeOptionsLoading"
                      :items="LomCodeOptions"
                      :rules="LomCodeRules"
                      v-model="LomCode"
                      required
                      outlined
                      dense
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                Credit Points &nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="#F19A3E"> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span class="mycard-text2">
                    Select the credit points, if applicable
                  </span>
                </v-tooltip>
              </v-card-title>
              <v-container>
                <v-row wrap class="mt-4">
                  <v-col cols="12" sm="12" md="3" lg="3">
                    <label>
                      <h6>Event Category <small>(optional)</small></h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      v-model="EventCategory"
                      :rules="EventCategoryRules"
                      :items="EventCategoryOptions"
                      :loading="EventCategoryOptionsLoading"
                      required
                      outlined
                      dense
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                More Information &nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="#F19A3E"> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span class="mycard-text2">
                    Enter more information about the event
                  </span>
                </v-tooltip>
              </v-card-title>
              <v-container>
                <v-row wrap class="mt-4">
                  <v-col cols="12" sm="12" md="3" lg="3">
                    <label>
                      <h6>
                        <span class="text-danger">* </span> Your role in the Event
                      </h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="FacultyTypeOptionsLoading"
                      :items="FacultyTypeOptions"
                      :rules="FacultyTypeRules"
                      v-model="FacultyType"
                      required
                      outlined
                      dense
                      clearable
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" lg="3">
                    <h6><span class="text-danger">*</span> Duration of the event</h6>
                    <v-text-field
                      type="number"
                      v-model="Duration"
                      :rules="DurationRules"
                      placeholder="Event duration"
                      hint="Enter event duration"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" lg="3">
                    <label>
                      <h6><span class="text-danger">* </span> Unit of the duration</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      v-model="Unit"
                      :rules="UnitRules"
                      :items="UnitOptions"
                      :loading="UnitOptionsLoading"
                      required
                      outlined
                      dense
                      clearable
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" lg="3">
                    <h6><span class="text-danger">*</span> No of Participants</h6>
                    <v-text-field
                      type="number"
                      v-model="TotalParticipants"
                      :rules="TotalParticipantsRules"
                      placeholder="Event participants count"
                      hint="Enter number of Participants"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                Event Invitation &nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="#F19A3E"> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span> Upload relavant event invitation, if any </span>
                </v-tooltip>
              </v-card-title>
              <p class="notes-text1">
                Upload relavant event invitation, if any. <br/> Note: Only Image format is
                permitted with size not exceeding 5 MB
              </p>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="5">
                    <label>
                      <h6 class="text-dark">Upload Invitation Image here</h6>
                    </label>
                    <v-file-input
                      v-model="InvitationImage.UploadedImage"
                      :rules="InvitationImageRules"
                      :clearable="false"
                      accept="image/png, image/jpeg, image/jpg"
                      prepend-icon="mdi-image"
                      append-outer-icon="mdi-delete"
                      truncate-length="25"
                      show-size
                      @change="previewInvitationImage"
                    >
                      <template v-slot:append-outer>
                        <v-icon color="red" @click="deleteInvitationImage()">
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="7"
                    v-if="InvitationImageUrl != ''"
                  >
                    <h6>Invitation Image Preview</h6>
                    <img :src="InvitationImageUrl" height="100px" width="100px" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                Event Images &nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="#F19A3E"> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span> Upload relavant event images, if any </span>
                </v-tooltip>
              </v-card-title>
              <p class="notes-text1">
                Upload relavant event image, if any. <br/> Note: Only Image format is
                permitted with size not exceeding 5 MB
              </p>
              <v-container>
                <v-btn
                  color="blue"
                  class="white--text"
                  rounded
                  v-if="EventImageMax > EventImageArray.length"
                  @click="addNewRow()"
                >
                  <!-- <v-icon>mdi-plus-circle</v-icon> -->
                  Add Event Images
                </v-btn>
                <br/><br/>
              <v-row v-if="EventImageArray.length > 0">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="4"
                  v-for="(row, index) in EventImageArray"
                  :key="index"
                >
                  <v-card>
                    <v-card-title class="text-right">
                      <v-row>
                        <v-col cols="12" sm="12" md="12" lg="10">
                          <h6 class="text-dark text-center">
                            Event Image - {{ index + 1 }}
                          </h6>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="2">
                          <v-icon color="red" @click="deleteRow(index)">
                            mdi-delete-circle-outline
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-file-input
                        v-model="row.EventImage"
                        :rules="row.EventImageRules"
                        truncate-length="25"
                        accept="image/png, image/jpeg"
                        label="Upload Event Photo Here"
                        prepend-icon="mdi-image"
                        append-outer-icon="mdi-delete"
                        :clearable="false"
                        show-size
                        @change="previewEventImage(index)"
                      >
                        <template v-slot:append-outer>
                          <v-icon color="red" @click="deleteImage(index)">
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-file-input>
                      <h6 align="center" v-if="row.EventImageUrl != ''">
                        <img
                          style="padding: 5px"
                          width="100"
                          height="100"
                          :src="row.EventImageUrl"
                        />
                      </h6>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <upload-progress
                  :ProgessStart="progessStart"
                  :UploadPercentage="uploadPercentage"
                ></upload-progress>
                <br />
                <v-btn
                  id="step12"
                  type="submit"
                  @click.prevent="confirmAlert"
                  :loading="submitFlag"
                  target="_blank"
                  color="#EDBE38"
                  class="font-size-h6 fsize-3 mr-3 my-3 white--text"
                >
                  <v-icon dark> mdi-briefcase-check </v-icon> &nbsp; Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      search: "",
      uploadPercentage: 0,
      LoadingFlag: false,
      OverlayLoadingFlag: false,
      valid1: true,
      submitFlag: false,

      DatePicker: false,
      ActivityDate: "",

      MemeberFullname: "",
      progessStart: 0,

      EventName: "",
      EventNameRules: [(v) => !!v || "Event name is required"],

      OrganizationTypeOptionsLoading: false,
      OrganizationTypeOptions: [],
      OrganizationTypeRules: [(v) => !!v || "Event level is required"],
      OrganizationType: "",

      EventCategoryOptionsLoading: false,
      EventCategoryOptions: [],
      EventCategoryRules: [
        // (v) => !!v || "Event Category is required"
      ],
      EventCategory: "",

      OrganizationTypeOptionsLoading: false,
      OrganizationTypeOptions: [],
      OrganizationTypeRules: [(v) => !!v || "Organization Type is required"],
      OrganizationType: "",

      FacultyTypeOptionsLoading: false,
      FacultyTypeOptions: [],
      FacultyTypeRules: [(v) => !!v || "Guest / Faculty Type is required"],
      FacultyType: "",

      ZoneCodeOptionsLoading: false,
      ZoneCodeOptions: [],
      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",

      LomCodeOptionsLoading: false,
      LomCodeOptions: [],
      LomCodeRules: [(v) => !!v || "LO is required"],
      LomCode: "",

      EventType: "",
      EventTypeOptionsLoading: false,
      EventTypeRules: [(v) => !!v || "Event Type is required"],
      EventTypeOptions: [
        { value: "", text: "--Select" },
        { value: 1, text: "Training event" },
        { value: 2, text: "Conference" },
        { value: 3, text: "Program Development)" },
      ],

      Duration: "",
      DurationRules: [(v) => !!v || "Duration is required"],

      TotalParticipants: 0,
      TotalParticipantsRules: [(v) => !!v || "Total participants is required"],

      Unit: "",
      UnitOptionsLoading: false,
      UnitRules: [(v) => !!v || "Unit is required"],
      UnitOptions: [
        { value: "", text: "--Select" },
        { value: 1, text: "Hour" },
        { value: 2, text: "Day" },
        { value: 3, text: "Event" },
        { value: 4, text: "Program" },
      ],

      InvitationImage: {},
      InvitationImageUrl: "",
      InvitationImageRules: [
        (v) =>
          !v ||
          v.size < 2000000 ||
          "Invitation image size should be less than 2 MB!",
      ],

      EventImageMax: 8,
      showButton: false,
      OldImageArray: [],
      EventImageArray: [],

      Row: {},
      ActivityId: "",

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;
        this.pageData();
      }
    },
    OrganizationTypeOptions: function () {
      console.log("watch OrganizationTypeOptions called");
      this.OrganizationTypeOptionsLoading = false;
    },
    FacultyTypeOptions: function () {
      console.log("watch FacultyTypeOptions called");
      this.FacultyTypeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions called");
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions called");
      this.LomCodeOptionsLoading = false;
    },
    ZoneCode: function () {
      console.log("watch ZoneCode called");
      this.getLomCodeOptions();
    },
    OldImageArray: function () {
      console.log("watch OldImageArray called");
      var old_image_count = this.OldImageArray.length;
      var event_image_count = this.EventImageArray.length;
      console.log({ old_image_count }, { event_image_count });
      this.showButton =
        this.EventImageMax > old_image_count
          ? this.EventImageMax > old_image_count + event_image_count
            ? true
            : false
          : false;
    },
    EventImageArray: function () {
      console.log("watch EventImageArray called");
      var old_image_count = this.OldImageArray.length;
      var event_image_count = this.EventImageArray.length;
      console.log({ old_image_count }, { event_image_count });
      this.showButton =
        this.EventImageMax > old_image_count
          ? this.EventImageMax > old_image_count + event_image_count
            ? true
            : false
          : false;
    },
    Row: function () {
      console.log("watch Row called");
      this.LoadingFlag = true;
      var tr = this.Row;
      this.OrganizationType = tr.OrganizationTypeId;
      this.EventName = tr.EventName;
      this.ZoneCode = tr.ZoneId;
      this.LomCode = tr.LomId;
      this.ActivityDate = tr.MemberActivityDate;
      this.EventType = tr.EventType;
      this.EventCategory = tr.TrainingEventId;
      this.FacultyType = tr.CategorySubId;
      this.Duration = tr.Duration;
      this.Unit = tr.UnitName;
      this.TotalParticipants = tr.TotalParticipants;
      this.InvitationImageUrl = tr.MemberActivityInvitaionImagePath;
      this.OldImageArray = tr.AttachmentArray;
      this.EventImageArray = tr.AttachmentArray;
      this.LoadingFlag = false;
    },
    EventCategoryOptions: function () {
      console.log("watch EventCategoryOptions called");
      this.EventCategoryOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "trainer_activity",
        Action: "edit",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");

      var id = this.$route.query.id;
      console.log({ id });
      this.ActivityId = id;

      var MemeberFullname = this.$session.get("MemeberFullname");
      MemeberFullname =
        MemeberFullname == (null || undefined) ? "" : MemeberFullname;
      console.log("MemeberFullname=" + MemeberFullname);
      this.MemeberFullname = MemeberFullname;

      this.resetAll();
      this.getOrganizationTypeOptions();
      this.getFacultyTypeOptions();
      this.getZoneCodeOptions();
      this.getEventCategoryOptions();
      this.getTableRecords();
    },
    resetAll() {
      console.log("resetAll called");
      this.OrganizationType = "";
      this.FacultyType = "";
      this.ZoneCode = "";
      this.LomCode = "";
      this.InvitationImage = {};
    },
    previewInvitationImage() {
      console.log("previewInvitationImage called");
      console.log("InvitationImage = ", this.InvitationImage);
      this.InvitationImageUrl = "";
      this.InvitationImageUrl = URL.createObjectURL(
        this.InvitationImage.UploadedImage
      );
    },
    deleteInvitationImage() {
      console.log("deleteInvitationImage called");
      this.InvitationImage = {};
      this.InvitationImageUrl = "";
    },
    previewEventImage(id) {
      console.log("previewEventImage called");
      console.log({ id });
      this.EventImageArray.forEach((row, index) => {
        console.log({ index });
        console.log({ row });
        if (id == index) {
          var url = URL.createObjectURL(row["EventImage"]);
          console.log({ url });
          row["EventImageUrl"] = url;
        }
      });
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      this.EventImageArray.forEach((row, index) => {
        console.log({ index });
        console.log({ row });
        if (id == index) {
          row["EventImage"] = {};
          row["EventImageUrl"] = "";
        }
      });
    },
    addNewRow() {
      console.log("addNewRow called");
      this.EventImageArray.push({
        EventImage: {},
        EventImageUrl: "",
      });
    },
    deleteRow(id) {
      console.log("deleteRow called");
      console.log({ id });
      this.EventImageArray.splice(id, 1);
    },
    deleteRow1(id) {
      console.log("deleteRow1 called");
      console.log({ id });
      this.OldImageArray.splice(id, 1);
    },
    getOrganizationTypeOptions() {
      console.log("getOrganizationTypeOptions called");

      this.OrganizationTypeOptionsLoading = true;
      var selectbox1_source = "OrganizationType";
      var selectbox1_destination = "OrganizationTypeOptions";
      var selectbox1_url = "api/organization-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getFacultyTypeOptions() {
      console.log("getFacultyTypeOptions called");

      this.FacultyTypeOptionsLoading = true;
      var selectbox1_source = "FacultyType";
      var selectbox1_destination = "FacultyTypeOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
        CategoryMainName: "event_faculty_type",
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");

      this.ZoneCodeOptionsLoading = true;
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/year-wise-lom/zone-options";
      var selectbox1_conditions_array = {
        UserInterface: 4,
        NotAvailable: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");

      var zone_code = this.ZoneCode;
      console.log({ zone_code });

      if (zone_code > 0) {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          ZoneCode: zone_code,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getEventCategoryOptions() {
      console.log("getEventCategoryOptions called");

      this.EventCategoryOptionsLoading = true;
      var selectbox1_source = "EventCategory";
      var selectbox1_destination = "EventCategoryOptions";
      var selectbox1_url = "api/training-event/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var validate1 = this.ActivityId > 0 ? true : false;
      console.log({ validate1 });

      if (validate1) {
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/member-activity/show";

        var upload = {
          UserInterface: 1,
          Activity: this.ActivityId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              console.log("records = " + JSON.stringify(records));
              thisIns.Row = records;
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
            thisIns.sweetAlert("error", error, false);
          });
      } else {
        var message = "Member activity details not found.";
        console.log({ message });
        thisIns.sweetAlert("error", message, false);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");

      var validate1 = this.$refs.form1.validate();
      console.log({ validate1 });

      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields.";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      this.OverlayLoadingFlag = true;

      var formData = {
        Activity: this.ActivityId,
        EventLevel: this.OrganizationType,
        EventName: this.EventName,
        HostZone: this.ZoneCode,
        HostLO: this.LomCode,
        EventDate: this.ActivityDate,
        EventType: this.EventType,
        EventCategory: this.EventCategory,
        YourRole: this.FacultyType,
        Duration: this.Duration,
        Unit: this.Unit,
        NoOfParticipants: this.TotalParticipants,
        EventImageCount: this.EventImageArray.length,
        EventImageArray: this.EventImageArray,
      };
      var Form = JSON.stringify(formData);
      console.log("Form=" + Form);

      let upload = new FormData();
      upload.append("UserInterface", 1);
      upload.append("Form", Form);

      console.log("UploadedImage = ", this.InvitationImage.UploadedImage);
      if (
        this.InvitationImage.UploadedImage != "" &&
        this.InvitationImage.UploadedImage != undefined
      ) {
        upload.append("InvitationImage", this.InvitationImage.UploadedImage);
      }

      this.EventImageArray.forEach((row, index) => {
        console.log({ index });
        console.log({ row });

        var image_file = null || undefined ? null : row["EventImage"];
        console.log({ image_file });

        upload.append("EventImage" + index, image_file);
      });

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      var add_url = server_url + "api/member-activity/update";
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.OverlayLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          records = response.data.records;
          console.log({ output }, { flag }, { records });

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);
            thisIns.$router.push("/my-activity/list");
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.OverlayLoadingFlag = false;

          thisIns.sweetAlert("error", output, false);
        });
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style lang="scss">
#member-activity-edit {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  #faculty-table {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
      font-size: 12px;
      color: red;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
      font-size: 15px;
    }
  }
  .mycard-head-title {
    // padding-bottom: 0;
    font-size: 20px;
    font-family: sans-serif;
    padding: 20px;
    text-align: center;
    word-break: break-word;
    line-height: normal;
    // text-transform: capitalize;
  }
  .mycard-title-1 {
    font-family: sans-serif;
    padding: 10px;
    text-align: left;
    word-break: break-word;
    line-height: normal;
    // text-transform: capitalize;
  }
  .mycard-title-2 {
    font-family: "Roboto", serif;
    padding: 10px;
    text-align: center;
    word-break: break-word;
    line-height: normal;
    // text-transform: capitalize;
  }
  .mycard-text1 {
    font-family: sans-serif;
    // text-transform: capitalize;
  }
  .mycard-text2 {
    font-size: 20px;
    font-family: "Merriweather", serif;
    color: red;
  }
  .notes-text1 {
    padding: 5px;
    margin-left: 2rem;
    font-size: 14px;
    font-family: sans-serif;
  }
  .mycard-text4 {
    font-size: 20px;
    font-family: "EB Garamond", serif;
    font-weight: bold;
    font-style: italic;
  }
  .mycard-text5 {
    font-size: 20px !important;
    font-family: "Roboto", serif;
    color: white;
  }
  .mycard-head-subtitle {
    padding-bottom: 0;
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-family: sans-serif;
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-family: sans-serif;
    font-size: 14px;
  }
  .animate-button1 {
    color: white;

    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
  }
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .note-text1 {
    margin-left: 2rem;
    font-size: 14px;
    padding: 5px;
  }
  .fsize-1 {
    font-size: 12px !important;
  }
  .fsize-2 {
    font-size: 14px !important;
  }
  .fsize-3 {
    font-size: 16px !important;
  }
  .fsize-4 {
    font-size: 18px !important;
  }
  .fsize-5 {
    font-size: 20px !important;
  }
  .fcolor-1 {
    color: #fff !important;
  }
  .bg-color-1 {
    background-color: #3a67b1;
  }
  .bg-color-2 {
    background-color: #0097d7;
  }
  .bg-color-3 {
    background-color: #edbe38;
  }
  .bg-color-4 {
    background-color: #56bda3;
  }
}
</style>